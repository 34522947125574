import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Page as Home } from "./home/Page";
import { Page as Entry } from "./entry/Page";
import { Page as Energy } from "./energy/Page";
import { Page as PointEarning } from "./pointEarning/Page";
import { Page as PointLimit } from "./pointLimit/Page";
import { Page as SecretariatContact } from "./secretariatContact/Page";
import { Page as SecretariatHistory } from "./secretariatHistory/Page";
import { Page as PointHistory } from "./pointHistory/Page";
import { Page as PresentList } from "./presentList/Page";
import { Page as Present } from "./present/Page";
import { Page as Error } from "./error/Page";
import { Page as Procedure } from "./procedure/Page";
import { Page as ContractInformationEntry } from "./contractInformationEntry/Page";

/**
 * ドキュメントroot
 */
export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/entry" element={<Entry />} />
        <Route path="/energy" element={<Energy />} />
        <Route path="/pointEarning" element={<PointEarning />} />
        <Route path="/pointLimit" element={<PointLimit />} />
        <Route path="/secretariatContact" element={<SecretariatContact />} />
        <Route path="/secretariatHistory" element={<SecretariatHistory />} />
        <Route path="/pointHistory" element={<PointHistory />} />
        <Route path="/list" element={<PresentList />} />
        <Route path="/detail" element={<Present />} />
        <Route path="/error" element={<Error />} />
        <Route path="/procedure" element={<Procedure />} />
        <Route path="/contractInformationEntry" element={<ContractInformationEntry />} />
      </Routes>
    </Router>
  );
}
