import { useState, useEffect } from "react";
import style from "../style/formParts.module.css";

/**
 * select
 */
export const Select = ({
  name,
  options = [],
  placeholder = false,
  defaultValue,
  setValue,
  fullWidth = false,
  validError,
  disabled = false
}) => {

  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [placeholderDisabled, setPlaceholderDisabled] = useState(Boolean(defaultValue));

  useEffect(() => {
    setSelectedValue(defaultValue);
    setPlaceholderDisabled(Boolean(defaultValue));
  }, [defaultValue]);

  const onChange = (event) => {
    setValue(event);
    setSelectedValue(event.target.value);
    if (event.target.value !== "") {
      setPlaceholderDisabled(true);
    }
  }

  const hasError = validError?.fieldErrorList && validError.fieldErrorList.some(fieldError => fieldError.field === name);

  return (
    <span
      className={fullWidth
        ? `${style["select-wrap-w-full"]}`
        : `${style["select-wrap"]}`
      }
    >
      <select
        name={name}
        className={`${fullWidth && style["w-full"]} ${hasError && style["error"]}`}
        value={selectedValue === "" ? undefined : selectedValue}
        style={{
          textAlign: "left",
          color: "silver"
        }}
        disabled={disabled}
        onChange={onChange}
      >
        {
          options.map((option, index) => (
            <option
              value={option.value}
              key={index}
            >
              {option.label}
            </option>
          ))
        }
      </select>
      <span className={style["select-arrow"]}></span>
    </span>
  );
}
