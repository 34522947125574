import style from "../style/confirmPopup.module.css";
import closeModal from "../image/close_modal.svg";

export const ConfirmPopup = ({
  isOpen,
  closeConfirmPopup,
  openExternalLinkConfirmPopup,
}) => {
  return (
    <div className={`${style["popup"]} ${isOpen && style["open"]}`}>
      <div className={style["popup-inner"]}>
        <img
          src={closeModal}
          alt="close modal"
          className={style["close"]}
          onClick={() => {
            closeConfirmPopup()
          }}
        />
        <p className={style["text"]}>
          お引越し直後の方へ<br /><br />
          このお手続きは、初回電気料金の請求があった後に開始してください。
        </p>
        <a
          className={style["button"]}
          onClick={() => {
            closeConfirmPopup();
            openExternalLinkConfirmPopup();
          }}>
          OK
        </a>
      </div>
    </div >
  );
}
