import {
  InputTitle,
  InputText,
  InputNumber,
  InputReadOnly
} from "./FormParts";
import style from "../style/content.module.css";

/**
 * 契約情報入力画面のコンテンツ
 * @param {string} lastName 姓
 * @param {string} firstName 名
 * @param {any} formData フォームデータ
 * @param {any} setValue 送信データの更新
 * @param {any} validError バリデーションエラー
 * @returns 契約情報入力画面のコンテンツ
 */
export const Content = ({ lastName, firstName, formData, setValue, validError }) => {
  return (
    <Section title="電気契約者情報のご確認">
      <h2 className={style["guide-title"]}>電気契約者のお名前を入力<br />してください</h2>
      <InputTitle
        title="お名前"
      />
      <div className={style["input-name-wrap"]}>
        <span className={style["text-blue"]}>姓</span>
        <InputReadOnly
          defaultValue={lastName}
        />
        <span className={style["text-blue"]}>名</span>
        <InputReadOnly
          defaultValue={firstName}
        />
      </div>
      <InputTitle
        title="フリガナ"
        required
      />
      <div className={style["input-name-wrap"]}>
        <span className={`${style["text-blue"]} ${style["hidden-text"]}`}>姓</span>
        <InputText
          value={formData.lastNameKana ?? ""}
          name="lastNameKana"
          maxlength={32}
          placeholder="セイ"
          setValue={setValue}
          validError={validError}
        />
        <span className={`${style["text-blue"]} ${style["hidden-text"]}`}>名</span>
        <InputText
          value={formData.firstNameKana ?? ""}
          name="firstNameKana"
          maxlength={32}
          placeholder="メイ"
          setValue={setValue}
          validError={validError}
        />
      </div>
      <div className={style["optional-guide"]}>
        <div className={style["optional-guide-inner"]}>
          <h2 className={style["guide-title"]}>おわかりの場合はご入力ください</h2>
          <InputTitle
            title="供給地点特定番号"
            optional
          />
          <div className={style["optional-guide-note"]}>
            <p>・電気契約会社のマイページ等で確認ができます</p>
            <p>・供給地点特定番号（例：00-1111-2222-3333 -4444-5555）は、ハイフンを除いてご入力ください</p>
          </div>
          <InputNumber
            value={formData.supplyPointNo ?? ""}
            name="supplyPointNo"
            maxlength={22}
            placeholder="0011112222333344445555(ハイフン無)"
            fullWidth
            setValue={setValue}
            validError={validError}
          />
        </div>
      </div>
    </Section>
  );
}

/**
 * 見出し
 * @param {string} title タイトル
 * @returns 見出し
 */
export const Section = ({title, children}) => {
  return (
    <>
      <SectionHeading title={title} />
      <SectionBody>
        {children}
      </SectionBody>
    </>
  );
}

/**
 * 見出し
 * @param {string} title タイトル
 * @returns 見出し
 */
export const SectionHeading = ({title}) => {
  return (
    <h2 className={style["section-heading"]}>
      {title}
    </h2>
  );
}

/**
 * コンテンツ
 * @returns コンテンツ
 */
export const SectionBody = ({children}) => {
  return (
    <div className={style["section-body"]}>
      {children}
    </div>
  );
}
