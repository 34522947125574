import style from "../style/buttonArea.module.css";

export const ButtonArea = ({
  openConfirmPopup,
  howToCheck
}) => {
  return (
    <div className={style["button-area"]}>
      <a className={style["button"]}
        onClick={ howToCheck === "0" ? openConfirmPopup : null }
        href={ howToCheck !== "0" ?
          "https://reg31.smp.ne.jp/regist/is?SMPFORM=qdoj-ljlfqb-99f3701207d8e0c6a3a264249bcb1e5b" :
          undefined
        }
      >手続きを開始する</a>
    </div>
  );
}
