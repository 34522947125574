import style from "../style/resultPopup.module.css";
import closeModal from "../image/close_modal.svg";

export const ResultPopup = ({
  isOpen,
  closeResultPopup,
  url,
  identificationRequestUrl,
}) => {
  return (
    <div className={`${style["popup"]} ${isOpen && style["open"]}`}>
      <div className={style["popup-inner"]}>
        <a href={url}>
          <img
            src={closeModal}
            alt="close modal"
            className={style["close"]}
          />
        </a>
        <p className={style["text"]}>
          本人確認へ進む<br /><br />
          ご登録のメールアドレスに本人確認手続きサイトのURLを送りました。<br />
          （noreply@denkankyo.jpより）<br />
          メールを開いて、手続きを進めてください。<br />
          24時間以内に完了しなかった場合は、【エントリー完了画面】からやり直してください。
        </p>
        <a className={style["button"]} href={url}>メール手続きをしてOK</a>
      </div>
    </div >
  );
}
