import style from "../style/button.module.css";
import { usePostRequest } from "../../../hooks/usePostRequest";

/**
 * 契約情報入力画面ボタンエリア
 */
export const ButtonArea = ({ openResultPopup, isRequiredValid, formData, setValidError, setIdentificationRequestUrl }) => {
  const API_URL = "/api/electricity/input";
  const { post, isLoading } = usePostRequest({
    url: API_URL,
    postData: formData,
    method: "post",
    onSuccess(responseData) {
      setIdentificationRequestUrl(responseData.identificationRequestUrl ?? "")
      openResultPopup()
    }
  });

  if (isRequiredValid) {
    return (
      <div className={style["buttonarea"]}>
        <a
          className={style["button"]}
          onClick={() => { onClick(formData, post, isLoading, setValidError) }}
        >次へ</a>
      </div>
    );
  }

  return (
    <div className={style["buttonarea"]}>
      <a
        className={style["button-not-click"]}
      >次へ</a>
    </div>
  )
}

const onClick = (formData, post, isLoading, setValidError) => {
  // post処理中にクリックされた場合にはイベントをキャンセルする
  if (isLoading) return;

  const clientValidResult = clientValid(formData);
  if (clientValidResult !== true) {
    setValidError({
      errorCode: "VALID_ERROR_FRONT",
      errorMessage: "VALID_ERROR_FRONT",
      fieldErrorList: clientValidResult
    });
    return;
  }
  post();
}

const clientValid = (formData) => {
  const fieldErrorList = [];
  const kanaRegex = /^[ｧ-ﾝﾞﾟァ-ヶー]+$/;
  // フリガナ（セイ）のバリデーション
  if (!kanaRegex.test(formData.lastNameKana)) {
    fieldErrorList.push({
      field: "lastNameKana",
      fieldErrorMessage: "形式が異なります。"
    });
  }
  // フリガナ（メイ）のバリデーション
  if (!kanaRegex.test(formData.firstNameKana)) {
    fieldErrorList.push({
      field: "firstNameKana",
      fieldErrorMessage: "形式が異なります。"
    });
  }
  // 供給地点特定番号のバリデーション
  const supplyPointNoRegex = /^(|\d{22})$/;
  if (formData.supplyPointNo && !supplyPointNoRegex.test(formData.supplyPointNo)) {
    fieldErrorList.push({
      field: "supplyPointNo",
      fieldErrorMessage: "形式が異なります。"
    });
  }

  // 入力値に問題がある場合、objectを返す
  if (fieldErrorList.length > 0) {
    return fieldErrorList;
  }
  // 入力値に問題がない場合、trueを返す
  return true;
} 
