import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Header } from "./component/Header";
import { Content } from "./component/Content";
import { ButtonArea } from "./component/ButtonArea";
import { Error } from "./component/Error";
import { ResultPopup } from "./component/ResultPopup";
import { usePopupStatus } from "../../hooks/usePopupStatus";
import { checkRequired } from "./utility/checkRequired";
import { useGetRequest } from "../../hooks/useGetRequest";

/**
 * 契約情報入力画面
 */
export const Page = () => {

  const [identificationRequestUrl, setIdentificationRequestUrl] = useState("");

  // 確認モーダルの管理
  const { isOpen: isOpenResultPopup, open: openResultPopup, close: closeResultPopup } = usePopupStatus();

  // フォームデータ
  const [formData, setFormData] = useState({});

  // リダイレクト元からstate,code,errorを取得して、内部API実行時に付与する
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const code = searchParams.get("code");
  const queryError = searchParams.get("error");
  let params = {};
  if (state !== null) {
    params.state = state;
  }
  if (code !== null) {
    params.code = code;
  }
  if (queryError !== null) {
    params.error = queryError;
  }

  // 内部APIの実行
  const API_URL = "/api/electricity/input";
  const { data, error, isLoading } = useGetRequest(API_URL, params);

  // 内部APIレスポンス取得時、フォームデータを更新する
  useEffect(() => {
    setFormData(prevState => {
      const newState = {
        ...prevState,
        ...data
      }
      newState["redirectUri"] = "https://service.appbox.jp/kurasus/denkankyofin/index.html";
      return newState;
    });
  }, [data]);

  // 送信データの更新
  const setValue = (event) => {
    setFormData((formData) => ({
      ...formData,
      [event.target.name]: event.target.value
    }));
  }

  // 必須チェック結果
  const [isRequiredValid, setIsRequiredValid] = useState(false);

  // フォームデータに変更があった時、必須チェックを実施する
  useEffect(() => {
    setIsRequiredValid(
      checkRequired(formData)
    );
  }, [formData])

  // バリデーション処理
  const [validError, setValidError] = useState({});
  useEffect(() => {
    setValidError({});
  }, [])

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <ResultPopup
        isOpen={isOpenResultPopup}
        closeResultPopup={closeResultPopup}
        url={"/"}
        identificationRequestUrl={identificationRequestUrl ?? ""}
      />
      <Header />
      {
        validError?.fieldErrorList && <Error fieldErrorList={validError.fieldErrorList} />
      }
      <Content
        lastName={data.lastName ?? ""}
        firstName={data.firstName ?? ""}
        formData={formData}
        setValue={setValue}
        validError={validError}
      />
      <ButtonArea
        openResultPopup={openResultPopup}
        isRequiredValid={isRequiredValid}
        formData={formData}
        setValidError={setValidError}
        setIdentificationRequestUrl={setIdentificationRequestUrl}
      />
    </>
  );
}
