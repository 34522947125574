import style from "../style/formParts.module.css";

/**
 * input title
 */
export const InputTitle = ({ title, required = false, optional = false }) => {
  return (
    <h3 className={style["input-title"]}>
      {title}
      {
        required && <RequiredIcon />
      }
      {
        optional && <OptionalIcon />
      }
    </h3>
  );
}

/**
 * required icon
 */
export const RequiredIcon = () => {
  return (
    <span className={style["required-icon"]}>
      必須
    </span>
  );
}

/**
 * optional icon
 */
export const OptionalIcon = () => {
  return (
    <span className={style["optional-icon"]}>
      任意
    </span>
  );
}

/**
 * input readonly
 */
export const InputReadOnly = ({ placeholder, defaultValue, fullWidth = false }) => {
  const className = fullWidth
    ? `${style["w-full"]} ${style["input-readonly"]}`
    : `${style["input-readonly"]}`;

  return (
    <>
      <input
        type="text"
        className={className}
        defaultValue={defaultValue}
        readOnly
      />
    </>
  );
}

/**
 * input text
 */
export const InputText = ({ placeholder, value, name, setValue, validError, maxlength = null, disabled = false }) => {
  const hasError = validError?.fieldErrorList && validError.fieldErrorList.some(fieldError => fieldError.field === name);
  return (
    <input
      type="text"
      className={`${hasError && style["error"]}`}
      name={name}
      placeholder={placeholder}
      value={value}
      maxLength={maxlength}
      onInput={setValue}
      disabled={disabled}
    />
  );
}

/**
 * input number
 */
export const InputNumber = ({ placeholder, value, name, setValue, validError, fullWidth = false, maxlength = 99, disabled }) => {
  const hasError = validError?.fieldErrorList && validError.fieldErrorList.some(fieldError => fieldError.field === name);

  return (
    <input
      type="number"
      name={name}
      className={`${style["input-number"]} ${fullWidth && style["w-full"]} ${hasError && style["error"]}`}
      placeholder={placeholder}
      value={value}
      maxLength={maxlength}
      disabled={disabled}
      onInput={(event) => {
        if (event.target.value.length > maxlength) {
          event.preventDefault();
          return;
        }
        setValue(event);
      }}
    />
  );
}
