import { convertFieldErrorList } from "../utility/convertFieldErrorList";
import style from "../style/error.module.css";

export const Error = ({ fieldErrorList }) => {
  const convertedFieldErrorList = convertFieldErrorList(fieldErrorList);
  return (
    <div className={style["error-section"]}>
      <div className={style["error-wrap"]}>
        <p>入力内容をご確認ください。</p>
        <br />
        {
          convertedFieldErrorList.map((fieldError, index) => (
            <p key={index}>{fieldError.fieldErrorMessage}</p>
          ))
        }
      </div>
    </div>
  );
}