import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const usePostRequest = ({
  url,
  postData,
  method = "post",
  onSuccess,
  onValidError
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const postRequest = () => {
    setIsLoading(true);
    axios({
      url,
      method,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(postData)
    })
      .then(response => { // 2XX
        if (onSuccess) {
          onSuccess(response.data);
        }
      })
      .catch(error => {
        if (error.response) { // 2XX以外
          const { status, data } = error.response;
          if (status === 400) {
            // fieldErrorListがnullの場合、もしくは、fieldErrorListが空の場合、もしくは、fieldErrorListの要素のうちfieldがnullの要素が一つでもある場合、共通エラーページに飛ばす判定
            const isNavigateToCommonErrorPage = data.fieldErrorList === null || data.fieldErrorList.length <= 0 || data.fieldErrorList.some(fieldError => fieldError.field === null);
            if (isNavigateToCommonErrorPage) { // 400で共通エラーページに飛ばすとき
              navigate("/error", { state: data });
            } else { // 400でエラー内容を表示するとき
              onValidError(data);
            }
          } else { // 400以外のエラー
            if (data.errorMessage === "home") {
              navigate("/");
            } else {
              navigate("/error", { state: data });
            }
          }
        } else { // レスポンスなし
          const unexpectedError = {
            errorMessage: "インターネットに接続できません\n通信環境の問題やシステムメンテナンスの可能性があります",
            errorCode: "EFNT002"
          }
          navigate("/error", { state: unexpectedError });
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  return {
    post: postRequest,
    isLoading
  }
}
