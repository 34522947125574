import { Link } from "react-router-dom";
import { Select } from "./FormParts";
import style from "../style/flow.module.css";
import drivers_license from "../image/procedure_drivers_license.png";
import mynumber_card from "../image/procedure_mynumber_card.png";
import triangle_down from "../image/procedure_triangle_down.svg";

export const Flow = ({
  setValue,
}) => {
  return (
    <div className={style["flow-body"]}>
      <h2 className={style["flow-heading"]}>電力データ提供のお手続き</h2>
      <div className={style["explanation-body"]}>
        <div className={style["explanation-area"]}>
          <div className={style["explanation-circle"]}>
            <span>同　意</span>
          </div>
          <div className={style["explanation-text-area"]}>
            <span className={style["explanation-text-font"]}>電力データ提供に</span><br />
            <span className={style["explanation-text-font-red"]}>同意</span>
            <span className={style["explanation-text-font"]}>してください</span>
          </div>
        </div>
      </div>
      <p className={style["note-text-font"]}>電力データ管理協会より電力データの提供を受けます</p>
      <div className={style["image-margin"]}>
        <img src={triangle_down} className={style["image-size-triangle-down"]} alt="triangle_down" />
      </div>
      <div className={style["explanation-body"]}>
        <div className={style["explanation-area"]}>
          <div className={style["explanation-circle"]}>
            <span>契約情報<br />入力</span>
          </div>
          <div className={style["explanation-text-area"]}>
            <span className={style["explanation-text-font"]}>電気契約者の情報を</span><br />
            <span className={style["explanation-text-font-red"]}>入力</span>
            <span className={style["explanation-text-font"]}>してください</span>
          </div>
        </div>
      </div>
      <div className={style["image-margin"]}>
        <img src={triangle_down} className={style["image-size-triangle-down"]} alt="triangle_down" />
      </div>
      <div className={style["explanation-body"]}>
        <div className={style["explanation-area"]}>
          <div className={style["explanation-circle-area"]}>
            <div className={style["explanation-circle"]}>
              <span>本人確認</span>
            </div>
          </div>
          <div className={style["explanation-inner-area"]}>
            <div className={style["explanation-text-area"]}>
              <span className={style["explanation-text-font-red"]}>本人確認</span>
              <span className={style["explanation-text-font"]}>をしてください</span>
            </div>
            <div className={style["explanation-image-area"]}>
              <div className={style["explanation-image-inner-area"]}>
                <img src={drivers_license} className={style["image-size-card"]} alt="drivers_license" />
                <span className={style["image-text-font"]}>運転免許証</span>
              </div>
              <div className={style["explanation-image-inner-area"]}>
                <img src={mynumber_card} className={style["image-size-card"]} alt="mynumber_card" />
                <span className={style["image-text-font"]}>マイナンバー<br />カードなど</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style["note-area"]}>
        <span className={style["note-text-font-red"]}>電気契約者ご本人の本人確認書類をご準備ください。</span><br />
        <span className={style["note-text-font-red"]}>電気の契約者が法人名義の場合、ご登録できません。</span><br />
        <span className={style["note-text-font-red"]}>一括受電サービス導入建物にお住まいの方は、このお手続きが不要です。<br />ご不明点があれば、<Link to={"/secretariatContact"}>事務局へお問い合わせ</Link>ください。</span>
      </div>
      <div className={style["input-area"]}>
        <div className={style["input-contents"]}>
          <span className={style["input-text-font"]}>ご本人確認の方法</span>
          <Select
            options={[
              { value: "0", label: "オンラインでご自身による確認" },
              { value: "1", label: "担当者による対面確認 ※" }
            ]}
            name="howToCheck"
            fullWidth={true}
            setValue={setValue}
            defaultValue="0"
          />
        </div>
        <span className={style["input-text-font-blue"]}>※「担当者による対面確認」は登録案内イベントや引渡手続き会場でのご登録時に選択ください。</span>
      </div>

    </div>
  );
}
