import { useState } from "react";
import { Flow } from "./component/Flow";
import { ButtonArea } from "./component/ButtonArea";
import { ConfirmPopup } from "./component/ConfirmPopup";
import { ExternalLinkConfirmPopup } from "./component/ExternalLinkConfirmPopup";
import { usePopupStatus } from "../../hooks/usePopupStatus";
import style from "./style/style.module.css";
import { useGetRequest } from "../../hooks/useGetRequest";

/**
 * 電力データ提供手続き画面
 */
export const Page = () => {

  // 確認モーダルの管理
  const { isOpen: isOpenConfirmPopup, open: openConfirmPopup, close: closeConfirmPopup } = usePopupStatus();

  // 外部リンク確認モーダルの管理
  const { isOpen: isOpenExternalLinkConfirmPopup, open: openExternalLinkConfirmPopup, close: closeExternalLinkConfirmPopup } = usePopupStatus();

  // ご本人確認の方法をstateで管理する
  const [howToCheck, setHowToCheck] = useState("0");

  // ご本人確認の方法の更新
  const setValue = (event) => {
    setHowToCheck(event.target.value);
  }

  // 内部APIの実行
  let params = {};
  params.redirectUri = `${window.location.origin}/contractInformationEntry`;
  const API_URL = "/api/electricity/start";
  const { data, error, isLoading } = useGetRequest(API_URL, params);

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <div className={style["page-body"]}>
        <ConfirmPopup
          isOpen={isOpenConfirmPopup}
          closeConfirmPopup={closeConfirmPopup}
          openExternalLinkConfirmPopup={openExternalLinkConfirmPopup}
        />
        <ExternalLinkConfirmPopup
          isOpen={isOpenExternalLinkConfirmPopup}
          closeExternalLinkConfirmPopup={closeExternalLinkConfirmPopup}
          url={data.authorizationRequestUri}
        />
        <Flow
          setValue={setValue}
        />
        <ButtonArea
          openConfirmPopup={openConfirmPopup}
          howToCheck={howToCheck}
        />
        <div className={style["note-area"]}>
          <p>申請することで、手続きが完了します。手続き途中で終わると、この画面からやり直しになります。</p>
        </div>
      </div>
    </>
  );
}