export const convertFieldErrorList = (fieldErrorList) => {
  return fieldErrorList.map(fieldError => {
    const { field, fieldErrorMessage } = fieldError;
    if (!mappingValidErrorMessage[field]) {
      return {
        field,
        fieldErrorMessage: null
      };
    }
    const target = mappingValidErrorMessage[field].find(item => item.message === fieldErrorMessage);
    return {
      field,
      fieldErrorMessage: target?.displayMessage ?? null
    };
  });
}

const mappingValidErrorMessage = {
  lastNameKana: [
    {
      message: "必須項目になります。",
      displayMessage: "フリガナ（セイ）を入力してください。"
    },
    {
      message: "形式が異なります。",
      displayMessage: "フリガナ（セイ）を正しく入力してください。"
    }
  ],
  firstNameKana: [
    {
      message: "必須項目になります。",
      displayMessage: "フリガナ（メイ）を入力してください。"
    },
    {
      message: "形式が異なります。",
      displayMessage: "フリガナ（メイ）を正しく入力してください。"
    }
  ],
  supplyPointNo: [
    {
      message: "形式が異なります。",
      displayMessage: "供給地点特定番号を正しく入力してください。"
    }
  ],
}

