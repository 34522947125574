import style from "../style/externalLinkConfirmPopup.module.css";
import closeModal from "../image/close_modal.svg";

export const ExternalLinkConfirmPopup = ({
  isOpen,
  closeExternalLinkConfirmPopup,
  url,
}) => {
  return (
    <div className={`${style["popup"]} ${isOpen && style["open"]}`}>
      <div className={style["popup-inner"]}>
        <img
          src={closeModal}
          alt="close modal"
          className={style["close"]}
          onClick={() => {
            closeExternalLinkConfirmPopup()
          }}
        />
        <p className={style["text"]}>
          電力データ提供同意手続きへ進む<br /><br />
          電力データ管理協会のサイトを表示します。<br />
          アカウントを新規登録し、ログインのうえ指示に従って同意してください。
        </p>
        <a className={style["button"]} href={url}>電力データ管理協会のサイトへ</a>
      </div>
    </div >
  );
}
