// 必須項目
const requiredFields = [
  "lastNameKana",
  "firstNameKana",
];

// 必須チェック処理
export const checkRequired = (formData) => {
  // 必須項目の必須チェックを実施する
  for (let name of requiredFields) {
    if (!formData[name]) {
      // 必須項目のなかで未入力の項目があった場合、falseを返す
      return false;
    }
  }

  // 必須項目が全て入力されている場合、trueを返す
  return true;
}
