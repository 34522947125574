import { useNavigate } from "react-router-dom";
import style from "../style/header.module.css";
import triangle from "../image/icon_triangle_right.svg";
import arrowIcon from "../image/header_arrow_left.svg";

/**
 * 契約情報入力画面ヘッダー
 */
export const Header = () => {
  const navigate = useNavigate();

  return (
    <>
      <header>
        <h1 className={style["page-heading"]}>
          <a
            className={style["arrow-icon"]}
            onClick={() => {
                navigate("/procedure");
            }}
          >
            <img
              src={arrowIcon}
              alt="arrow"
            />
          </a>
          契約情報入力
        </h1>
      </header>
      <SubHeaderDuringContract />
    </>
  );
}
const SubHeaderDuringContract = () => {
  return (
    <div>
      <div className={style["contract-flow"]}>
        <div className={style["contract-circle-blue"]}>
          <p className={style["contract-circle-text"]}>同　意</p>
        </div>
        <img src={triangle} alt="triangle" />
        <div className={style["contract-circle-red"]}>
          <p className={style["contract-circle-text"]}>契約情報</p>
          <p className={style["contract-circle-text"]}>入力</p>
        </div>
        <img src={triangle} alt="triangle" />
        <div className={style["contract-circle"]}>
          <p className={style["contract-circle-text"]}>本人確認</p>
        </div>
      </div>
    </div>
  );
}
